import PaperStepsModule from 'components/admin/PaperStepsModule';
import SinglechoiceModule from 'components/admin/SinglechoiceModule'
import PreviewSinglechoiceModule from 'components/admin/PreviewSinglechoiceModule'
import MultiplechoiceModule from 'components/admin/MultiplechoiceModule'
import PreviewMultiplechoiceModule from 'components/admin/PreviewMultiplechoiceModule'
import JudgeechoiceModule from 'components/admin/JudgeechoiceModule'
import PreviewJudgeechoiceModule from 'components/admin/PreviewJudgeechoiceModule'
import FillblanksModule from 'components/admin/FillblanksModule'
import PreviewFillblanksModule from 'components/admin/PreviewFillblanksModule'
import QuestionsAnswersModule from 'components/admin/QuestionsAnswersModule'
import PreviewQuestionsAnswersModule from 'components/admin/PreviewQuestionsAnswersModule'
import TestColumnModule from 'components/admin/TestColumnModule';
import _ from 'underscore';
import ca from "element-ui/src/locale/lang/ca";
import functions from "../../../utils/common.js";
export default {
    name: "AddPaper",
    components: {
        PaperStepsModule,
        SinglechoiceModule,
        PreviewSinglechoiceModule,
        MultiplechoiceModule,
        PreviewMultiplechoiceModule,
        JudgeechoiceModule,
        PreviewJudgeechoiceModule,
        FillblanksModule,
        PreviewFillblanksModule,
        QuestionsAnswersModule,
        PreviewQuestionsAnswersModule,
        TestColumnModule,
    },
    created() {
        //获取专业分类
        this.getClasses();
        //试题列表
        this.getQUestions();
        if (this.$route.query.paperId) {
            this.getExamPaperInfo(this.$route.query.paperId);
        }
    },
    data() {
        return {
            examPagerId: 0,
            theClass: null, //已选的专业分类
            classes: [],  //所有的专业分类
            classProps: {
                value: 'rc_id',
                label: 'rc_name'
            },
            classId: 0,
            theTags: [],
            tagsProps: {
                label: 'name',
                children: 'children'
            },
            theTagsId: 0, //标签组id
            StepNum: 1, //当前的操作步骤,
            theQuestionType: 1, //默认选择单选题
            theQuestionTypeArr: ['单选题', '多选题', '判断题', '填空题', '问答题'],
            theQuestionDifficulty: ['容易', '较易', '普通', '较难', '困难'],
            theDiffIndex: 1,
            tagId: 0, //当前标签id
            sortType: 'new_update',
            topicContent: '',
            isShow: false,
            questionList: [],
            questionTotal: 0,
            allIn: false,
            questionIds: [],
            currentPage: 1,
            limitPage: 10,
            examPaper: {
                classId: 0,
                name: '',
                content: {
                    single: [],
                    multiple: [],
                    judge: [],
                    fillBlank: [],
                    askAnswer: []
                },
                scoreTotal: 0,
                paper_number: 0
            }
        }
    },
    computed: {
        totalScore() {
            let total = 0;
            for(let key in this.examPaper.content) {
                _.map(this.examPaper.content[key], (item) => {
                    total += parseInt(item.score);
                });
            }
            return total;
        }
    },
    methods: {
        getQtype(value) {
            let indexStr = '';
            switch (value) {
                case 'single':
                    indexStr = functions.translateNumberByObject(this.examPaper.content, 'single');
                    return `${indexStr}、单选题`;
                    break;
                case 'multiple':
                    indexStr = functions.translateNumberByObject(this.examPaper.content, 'multiple');
                    return `${indexStr}、多选题`;
                    break;
                case 'judge':
                    indexStr = functions.translateNumberByObject(this.examPaper.content, 'judge');
                    return `${indexStr}、判断题`;
                    break;
                case 'fillBlank':
                    indexStr = functions.translateNumberByObject(this.examPaper.content, 'fillBlank');
                    return `${indexStr}、填空题`;
                    break;
                case 'askAnswer':
                    indexStr = functions.translateNumberByObject(this.examPaper.content, 'askAnswer');
                    return `${indexStr}、问答题`;
                    break;
            }
        },
        getExamPaperInfo(examPaperId) {
            this.$http.axiosGetBy(this.$api.findTestPaper, {id: examPaperId}, (res) => {
                if (res.code === 200) {
                    let response = res.data;
                    this.examPagerId = response.id;
                    this.examPaper.classId = response.rc_array;
                    this.examPaper.name = response.paper_name;
                    this.examPaper.paper_number = response.paper_number;
                    this.examPaper.scoreTotal = response.paper_total;
                    this.examPaper.content = JSON.parse(response.paper_content);
                    //获取试卷试题的所有id
                    _.map(this.examPaper.content, (item) => {
                        _.map(item, (val) => {
                            this.questionIds.push(val.id);
                        });
                    });
                }
            }, (err) => {
                console.log(err);
            })
        },
        getQTypeTotal(item) {
            let total = 0;
            _.map(item, (val) => {
                total += parseInt(val.score);
            });
            return total;
        },
        getClasses() {
            this.$http.axiosGetBy(this.$api.resourceClassList, { type: 1 }, (res) => {
                if (res.code === 200) {
                    //过滤掉children为空
                    _.map(res.list, function(item) {
                        if (item.children.length) {
                            _.map(item.children, function(sitem) {
                                if (sitem.children.length) {
                                    _.map(sitem.children, function (ssitem) {
                                        if (ssitem.children.length === 0) {
                                            delete ssitem.children;
                                        }
                                    });
                                } else {
                                    delete sitem.children;
                                }
                            });
                        } else {
                            delete item.children;
                        }
                    });
                    this.classes = res.list;
                }
            }, (err) => {
                console.log(err);
            })
        },
        getTags() {
            // 获取最后一级的分类值
            this.classId = this.theClass[this.theClass.length -1];
            let params = {};
            //获取标签组
            if (this.classId) {
                params.rc_id = this.classId;
            }
            //请求试题列表
            this.getQUestions();
            this.$http.axiosGetBy(this.$api.showTag, params, (res) => {
                if (res.code === 200) {
                    this.theTags = res.data.data;
                } else {
                    this.$message({
                        type: "warning",
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                console.log(err);
            })
        },
        handleNodeClick(data) {
            if (data.sid === 0) {
                this.theTagsId = data.id;
                this.tagId = 0;
            } else {
                this.theTagsId = data.sid;
                this.tagId = data.id;
            }
            this.getQUestions();
        },
        getQUestions() {
            let params = {};
            if (this.theTagsId) {
                params.tag_sid = this.theTagsId;
            }
            if (this.tagId) {
                params.tag_id = parseInt(this.tagId);
            }
            if (this.classId) {
                params.rc_id = this.classId;
            }
            if (this.theQuestionType) {
                params.topic_type = this.theQuestionType;
            }
            if (this.theDiffIndex) {
                params.topic_difficulty = this.theDiffIndex;
            }

            if (this.sortType) {
                params.type = this.sortType;
            }

            if (this.topicContent) {
                params.topic = this.topicContent;
            }

            if (this.currentPage) {
                params.page = this.currentPage;
                params.limit = this.limitPage;
            }

            this.$http.axiosGetBy(this.$api.showTopic, params, (res) => {
                if (res.code === 200) {
                    this.questionTotal = res.data.total;
                    if (res.data.total) {
                        this.questionList = _.map(res.data.data, function(item) {
                            item.topic = JSON.parse(item.topic)
                            item.score = 0;
                            return item;
                        });
                    } else {
                        this.questionList = [];
                    }
                } else {
                    this.$message({
                        type: 'warning'
                        ,message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                console.log(err);
            });
        },
        changeStep(val) {
            if (val) {
                //下一步
                if (this.StepNum === 1 ) {
                    if (this.questionIds.length) {
                        this.StepNum++;
                    } else {
                        this.$message({
                            type: 'error',
                            message: '请添加试题到试卷',
                            duration: 1000
                        });
                    }
                } else if (this.StepNum === 2) {
                    if (this.examPaper.name.length === 0 || (this.examPaper.name !== '0' && Number(this.examPaper.name) === 0)) {
                        this.$message({
                            type: 'error',
                            message: '请设置试卷名称',
                            duration: 1000
                        });
                    } else if (this.examPaper.classId.length === 0 || this.examPaper.classId === 0) {
                        this.$message({
                            type: 'error',
                            message: '请选择试卷分类',
                            duration: 1000
                        });
                    } else {
                        this.StepNum++;
                    }
                }
            } else {
                //上一步
                if (this.StepNum > 1) {
                    this.StepNum--;
                } else {
                    this.StepNum = 1;
                }
            }
        },
        tabClick(index) {
            this.theQuestionType = index;
            console.log('类型', this.theQuestionType);
            this.getQUestions();
        },
        difficultyClick(index) {
            this.theDiffIndex = index;
            this.getQUestions();
        },
        tabSort(sort) {
            this.sortType = sort;
            this.getQUestions();
        },
        searchQuestion() {
            this.getQUestions();
        },
        addAllInPaper() {
            switch (this.theQuestionType) {
                case 1:
                    this.addAllSameQuestion('single', this.questionList);
                    break;
                case 2:
                    this.addAllSameQuestion('multiple', this.questionList);
                    break;
                case 3:
                    this.addAllSameQuestion('judge', this.questionList);
                    break;
                case 4:
                    this.addAllSameQuestion('fillBlank', this.questionList);
                    break;
                case 5:
                    this.addAllSameQuestion('askAnswer', this.questionList);
                    break;
            }
        },
        addAllSameQuestion(questionType, questionList) {  //同类型题目全部加入试卷
            if (this.questionList.length) {
                _.map(questionList, (item) => {
                    if (this.questionIds.indexOf(item.id) === -1) {
                        this.questionIds.push(item.id);
                        this.examPaper.content[questionType].push(item);
                    }
                });
                this.examPaper.paper_number = this.questionIds.length;
            }
        },
        addQInPaper(data) {
            let isExist = this.questionIds.indexOf(data.id);
            if (isExist === -1) {
                //加入试卷
                let question = {};
                for(let i in data) {
                    question[i] = data[i];
                }
                this.questionIds.push(question.id);
                switch (question.topic_type) {
                    case 1:
                        this.examPaper.content.single.push(question);
                        break;
                    case 2:
                        this.examPaper.content.multiple.push(question);
                        break;
                    case 3:
                        this.examPaper.content.judge.push(question);
                        break;
                    case 4:
                        this.examPaper.content.fillBlank.push(question);
                        break;
                    case 5:
                        this.examPaper.content.askAnswer.push(question);
                        break;
                }
            } else {
                //从试卷移除
                this.removeQuestionByItem(data);
            }
            this.examPaper.paper_number = this.questionIds.length; //题目总数
        },
        deleteOneQ(data) {
            this.removeQuestionByItem(data);
            if (this.questionIds.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请重新选题，谢谢~',
                    duration: 1000,
                    onClose: () => {
                        this.StepNum = 1;
                    }
                });
            }
        },
        removeQuestionByItem(item) {
            switch (Number(item.topic_type)) {
                case 1:
                    this.removeQuestionById('single', item.id);
                    break;
                case 2:
                    this.removeQuestionById('multiple', item.id);
                    break;
                case 3:
                    this.removeQuestionById('judge', item.id);
                    break;
                case 4:
                    this.removeQuestionById('fillBlank', item.id);
                    break;
                case 5:
                    this.removeQuestionById('askAnswer', item.id);
                    break;
            }
        },
        removeQuestionById(questionType, qId) {
            //从试题ids组移除
            this.questionIds = this.questionIds.filter(function(elem) {
                return elem !== qId;
            })
            this.examPaper.content[questionType] = _.filter(this.examPaper.content[questionType], (item) => {
                return item.id !== qId;
            })
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.getQUestions();
        },
        questionsClear() {
            this.examPaper = {
                name: '',
                content: {
                    single: [],
                    multiple: [],
                    judge: [],
                    fillBlank: [],
                    askAnswer: []
                },
                scoreTotal: 0,
                paper_number: 0
            };
            this.questionIds = [];
        },
        removeOneQ(data) {
            switch (data) {
                case 'single':
                    this.removeTopic('single');
                    break;
                case 'multiple':
                    this.removeTopic('multiple');
                    break;
                case 'judge':
                    this.removeTopic('judge');
                    break;
                case 'fillBlank':
                    this.removeTopic('fillBlank');
                    break;
                case 'askAnswer':
                    this.removeTopic('askAnswer');
                    break;
            }
        },
        removeTopic(topicType) {
            let questiomTmp = this.examPaper.content[topicType];
            this.examPaper.content[topicType] = [];
            let qIdsTmp = _.map(questiomTmp, function(item) {
                return item.id;
            });
            this.questionIds = this.questionIds.filter(function (item) {
                return qIdsTmp.indexOf(item) === -1;
            })
            this.examPaper.paper_number = this.questionIds.length;
        },
        saveExam() {
            //
            let formData = new FormData();
            formData.append('paper_name', this.examPaper.name);
            formData.append('rc_id', this.examPaper.classId[this.examPaper.classId.length-1]);
            formData.append('paper_content', JSON.stringify(this.examPaper.content));
            formData.append('paper_number', this.examPaper.paper_number);
            //计算试卷总分
            let totalScore = 0;
            //判断是否有未设置分值的题目
            let isNone = false;
            _.map(this.examPaper.content, (item) => {
                _.map(item, (val) => {
                    totalScore += parseInt(val.score);
                    if (Number(val.score) === 0) {
                        isNone = true;
                    }
                });
            });
            if (isNone) {
                this.$message({
                    type: 'warning',
                    message: '还有试题未设置分值！',
                    duration: 1000
                });
                return false;
            }
            if (totalScore > 100 || totalScore < 100) {
                this.$message({
                    type: 'error',
                    message: '试卷总分不能大于100分或者少于100分',
                    duration: 2000
                });
                return false;
            }
            formData.append('paper_total', totalScore);
            if (this.examPagerId) {
                formData.append('id', parseInt(this.examPagerId));
            }
            this.$http.axiosPost(this.$api.saveTestPaper, formData, (res) => {
                if (res.code === 200) {
                    //同时累计试题的组卷次数
                    if (this.questionIds.length) {
                        let params = {
                            topic_ids: this.questionIds.join(',')
                        };
                        this.$http.axiosGetBy(this.$api.setIncTopic, params, (result) => {
                            if (result.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: result.msg,
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push({path: '/admin/index',query:{ttype: 2, mtype: 2}});
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: result.msg,
                                    duration: 2000
                                });
                            }
                        }, (err) => {

                        })
                    }

                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                console.log(err);
            })
        }
    }
}