<template>
    <div class="selectedStep-top">
        <div  :class="step==1?'Pre blue':'Pre activeStep'" @click="preClick()">
            <i class="iconfont">&#xe665;</i>
            <span>上一步</span>
        </div>
        <div class="step-content">
            <div data-id="1"  :class="step==1?'step-border  blue':'step-border'">
                <i class="iconfont">&#xe6a9;</i>选题
            </div>
            <span class="setp-line"></span>
            <div data-id="2"  :class="step==2?'step-border  blue':'step-border'">
                <i class="iconfont">&#xe611;</i>预览
            </div>
            <span class="setp-line"></span>
            <div data-id="3"  :class="step==3?'step-border blue':'step-border'">
                <i class="iconfont">&#xe731;</i>保存
            </div>
        </div>
        <div :class="step==3?'Next blue':'Next activeStep'" @click="nextClick()">
            <i class="iconfont " >&#xe665;</i>
            <span>下一步</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaperSteps",
        data(){
            return{
            }
        },
        created() {
        },
        props: ['step'],
        methods:{
            preClick(){
                this.$emit("progress", 0);
            },
            nextClick(){
                this.$emit("progress", 1);
            },
        },
    }
</script>

<style scoped lang="scss">
    .selectedStep-top{
        height:88px;
        line-height:88px;
        border-bottom: 2px solid #409EFF;
        display: -webkit-box;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        -webkit-box-align: center;
        align-items: center;
        .Pre{
            display: flex;
            display: -webkit-flex;
            -webkit-box-direction: normal;
            flex-direction: column;
            font-size: 12px;
            cursor: pointer;
            i{
                height: 20px;
                line-height: 20px;
                font-size: 30px;
                margin-right: 6px;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            span {
                line-height: 30px;
                height: 30px;
                display: inline-block;
            }
        }
        .activeStep{
            color: #409eff;
        }
        .step-content{
            display: flex;
            display: -webkit-flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            .step-border {
                height: 40px;
                line-height: 40px;
                background: #ddd;
                color: #fff;
                padding: 0 10px;
                border-radius: 4px;
                cursor: pointer;
                i{
                    padding-right: 10px;
                }
            }
            .blue{
                background: #409eff;
                color: #fff;
            }
            .blue+.setp-line {
                background-image: url(../../assets/img/image/blue-line.png);
                height: 10px;
                margin-left: 10px;
                width: 162px;
                background-repeat: no-repeat;
            }
            .setp-line {
                background-image: url(../../assets/img/image/hui-line.png);
                height: 10px;;
                margin-left: 10px;
                width: 162px;
                background-repeat: no-repeat;
            }
        }
        .Next{
            display: flex;
            display: -webkit-flex;
            -webkit-box-direction: normal;
            flex-direction: column;
            font-size: 12px;
            cursor: pointer;
            i{
                height: 20px;
                line-height: 20px;
                font-size: 30px;
                margin-left: 6px;
            }
            span {
                line-height: 30px;
                height: 30px;
                display: inline-block;
            }
        }
    }
</style>