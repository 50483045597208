<template>
    <div v-if="data.length > 0">
        <div class="questiontype-content" v-for="(item,index) in data" :key="index">
            <div class="questiontype-top">
                <div class="Title"><span class="Title-span">[题文]{{getIndex(index)}}、</span><span v-html="item.topic.title" class="html-span"></span></div>
            </div>
            <div class="answer-analysis" v-show="isShow">
                <div class="answer-analysis-top">
                    <span class="answer-analysis-label">[解析]</span>
                    <span class="answer-analysis-text" v-html="item.topic.analyse" v-if="item.topic.analyse"></span>
                    <span class="answer-analysis-text" v-else>略</span>
                </div>
                <div class="answer-analysis-bottom">
                    <span class="answer-analysis-label">[答案]</span>
                    <span class="answer-analysis-text" v-if="item.topic.options">
                        <template v-for="(optionItem,index2) in item.topic.options.option">
                            {{index2===0?'第一空:':index2===1?'第二空:':index2===2?'第三空:':'第四空:'}}
                            {{optionItem.name}}
                        </template>
                    </span>
                </div>
            </div>
            <div class="answer-analysis-foot">
                <div class="answer-analysis-foot-left">
                    <span>难度:{{item.topic_difficulty === 1?'容易':item.topic_difficulty === 2?'较易':item.topic_difficulty === 3?'普通':item.topic_difficulty === 4?'较难':'困难'}}</span>
                    <span>题型:填空题</span>
                    <span>组卷次数:{{item.number}}次</span>
                </div>
                <div class="answer-analysis-foot-right">
                    <el-button type="primary" :class="isAdd(item) ? 'remove-analysisBtn':'add-analysisBtn'" @click="addPaper(item)">
                        <i class="iconfont" >{{isAdd(item) ? '&#xe729;':'&#xe632;'}}</i>
                        {{isAdd(item) ? '从试卷移除' : '加入试卷'}}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
    <div class="null-data" v-else>暂无数据</div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "FillblanksModule",
        data(){
            return{
            }
        },
        props: {
            data: {
                type: Array,
                default: []
            },
            isShow: {
                type: Boolean,
                default: false
            },
            qIds: {
                type: Array,
                default: []
            },
            limit: {
                type: Number,
                default: 10
            },
            currentPage: {
                type: Number,
                default: 1
            }
        },
        methods: {
            //加入试卷
            addPaper(item) {
                this.$emit('putInPaper', item);
            },
            isAdd(item) {
                let value = _.find(this.qIds, function(id) {
                    return id === item.id;
                });
                if (value) {
                    return true;
                } else {
                    return false;
                }
            },
            getIndex(index) {
                return (this.currentPage - 1) * this.limit + (index+1);
            }
        }
    }
</script>

<style scoped lang="scss">
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ::v-deep .html-span{
        p{
            padding: 0;
            margin: 0;
        }
    }
    .questiontype-content{
        border: 1px solid #deedff;
        font-size: 14px;
        color: #333;
        margin-bottom: 10px;
        .questiontype-top{
            display: flex;
            display: -webkit-flex;
            font-size: 12px;
            color: #333;
            font-weight: 700;
            padding: 4px 4px;
            .Title{
                display: -webkit-box;
                display: flex;
                font-size: 12px;
                color: #333;
                font-weight: 700;
                padding: 4px 4px;
                align-items: center;
                -webkit-align-items: center;

                .Title-span{
                    min-width:36px;
                    display: inline-block;
                }
            }
        }
        .answer-analysis{
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            margin-bottom: 10px;
            .answer-analysis-top{
                font-size: 12px;
                margin:10px 0 0 10px;
            }
            .answer-analysis-label{
                color: #409eff;
            }
            .answer-analysis-text{
                display: inline-block;
                margin-left: 10px;
                font-size: 12px;
            }
            .answer-analysis-bottom{
                font-size: 12px;
                margin:10px 0 0 10px;
            }
        }
        .answer-analysis-foot{
            display: flex;
            display: -webkit-flex;
            justify-content: start;
            -webkit-justify-content: flex-start;
            background: #f5f9ff;
            height: 40px;
            line-height: 40px;
            padding-left: 10px;
            margin-top: 25px;
            font-size: 12px;
            font-weight: 400;
            .answer-analysis-foot-left{
                display: flex;
                display: -webkit-flex;
                flex: 1;
                width: 1%;
                span{
                    display: inline-block;
                    margin-right: 10px;
                }
            }
            .add-analysisBtn{
                padding:6px 4px;
                margin-right:10px;
            }
            .remove-analysisBtn{
                background-color:#F56C6C;
                border-color: #F56C6C;
                padding:6px 4px;
                margin-right:10px;
            }
        }

    }
    .null-data{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:14px;
        height: 558px;
    }
</style>