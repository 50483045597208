<template>
    <div class="Test-border">
        <div v-if="multipleData.multiple.length>0">
            <p class="Test-paper-title">{{getTranslateIndex}}、多选题</p>
            <draggable :list="multipleData.multiple" handle=".move-item">
                <div class="Test-paper-select Test-border Test-border-hover" v-for="(question,index) in multipleData.multiple" :key="index">
                    <div class="Test-border-border">
                        <div class="Test-paper-topic">
                            <span class="serial-number">{{index+1}}、</span>
                            <span v-html="question.topic.title" class="html-span"></span>
                        </div>
                        <div class="Test-Answer">
                            <ul class="Test-paper-test">
                                <li v-for="(steptwoitem,index2) in question.topic.options.option" :key="index2">
                                    <span>{{index2===0?'A':index2===1?'B':index2===2?'C':index2===3?'D':index2===4?'E':'F'}}、{{steptwoitem.name}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="Test-paper-op">
                            <span class="anticon Anticonblue move-item">
                                <i class="el-icon-rank"></i>
                            </span>
                                <span class="anticon Anticondel" @click="deleteOne(question)">
                                 <i class="iconfont">&#xe879;</i>
                            </span>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import functions from "../../utils/common";
    export default {
        name: "PreviewMultiplechoiceModule",
        data(){
            return{
            }
        },
        computed: {
            getTranslateIndex() {
                return functions.translateNumberByObject(this.multipleData, 'multiple');
            }
        },
        components: { draggable },
        props: ['multipleData'],
        methods: {
            deleteOne(item) {
                this.$emit('deleteItem', item);
            }
        }
    }
</script>

<style scoped lang="scss">
    .Test-border{
        display: -webkit-box;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: left;
        padding: 0 30px 0 6px;
        border: 1px solid transparent;
        flex: 1;
        position: relative;
        .serial-number{
            float: left;
        }
        .Test-paper-title{
            padding: 15px 4px;
            font-weight: 700;
            margin: 0;
        }
        .Test-paper-op{
            position: absolute;
            right: -30px;
            top:10px;
            display: none;
            cursor: pointer;
            .anticon{
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ddd;
                width: 30px;
                height: 30px;
                color: #fff;
                border-radius: 2px;
            }
            .Anticonblue{
                background: #409eff;
                color: #fff;
                &.move-item {
                    margin-bottom: 5px;
                }
            }
            .Anticondel{
                background: #f56c6c;
                color: #fff;
            }
        }
        .Test-paper-test{
            color: #333;
            padding-left: 10px;
            li{
                margin-bottom: 10px;
                list-style: none;
            }
        }
        .Test-border-border{
            position: relative;
            border: 1px solid transparent;
        }
        .Test-border-hover:hover .Test-paper-op {
            display: block;
        }
        .Test-border-hover:hover .Test-border-border {
            border: 1px solid #409eff;
        }
        .Test-paper-topic {
            font-weight: 700;
            margin: 0;
            line-height: 2;
        }
    }
</style>