<template>
    <div class="TestColumn-content">
        <el-badge :value="data.paper_number" class="item">
            <div class="TestColumn-title" id="showArea" @click="toBasket">
                <span class="TestColumn-Icon"><i class="iconfont">&#xe633;</i></span>
                <span style="color: #409EFF;font-size: 10px">试题蓝</span>
            </div>
        </el-badge>
        <div class="TestColumn-dialog"  v-show="basketShow">
            <div class="TestColumn-dialog-top">我的试卷</div>
            <div class="TestColumn-dialog-body">
                <div class="TestColumn-dialog-num">最新加入试题(<span style="margin: 0 10px;">(共<em>{{data.paper_number}}</em>题)</span></div>
                <div class="TestColumn-dialog-type" v-for="(item,index) in data.content" v-show="item.length>0">
                    <span class="TestColumn-dialog-title">{{index | translateType}}</span>
                    <span class="TestColumn-dialog-title">{{item.length}}题</span>
                    <button class="TestColumn-dialog-del" @click="removeQuestion(index)">删除</button>
                </div>
                <div class="TestColumn-dialog-add" v-show="data.paper_number === 0"> 请先加入试题</div>
                <div class="TestColumn-dialog-foot">
                    <div class="TestColumn-dialog-foot-left">
                        <span @click="emptyTopic()">清空全部</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TestColumnModule",
        data(){
            return{
                basketShow: false,
            }
        },
        props: ['data'],
        filters: {
            translateType: function (value) {
                let name = '';
                switch (value) {
                    case 'single':
                        name = '单选题';
                        break;
                    case 'multiple':
                        name = '多选题';
                        break;
                    case 'judge':
                        name = '判断题';
                        break;
                    case 'fillBlank':
                        name = '填空题';
                        break;
                    case 'askAnswer':
                        name = '问答题';
                        break;
                }
                return name;
            }
        },
        methods:{
            removeQuestion(index){
                this.$confirm('确定删除试题?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 试题总数 扣除 被删除题型 的题目数量
                    this.$emit('removeOne', index);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 把试题蓝中的每一种题型清零
            emptyTopic(){
                this.$confirm('确定清空试题蓝?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //循环题型栏中的题型数组
                    this.$emit('clearAll', true);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 试题栏点击事件
            toBasket() {
                this.basketShow = !this.basketShow;
            }


        }
    }
</script>

<style scoped lang="scss">
    .TestColumn-content{
        position: relative;
        right: -10px;
        top: 164px;
        height: 60px;
        z-index: 999;
        .TestColumn-title{
            cursor: pointer;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            align-items: center;
            -webkit-align-items: center;
            background: #fff;
            width: 48px;
            border-radius: 8px;
            font-size: 12px;
            .TestColumn-Icon{
                display: inline-block;
                height: 32px;
                line-height: 32px;
                -webkit-text-fill-color: #409eff;
                color: #409eff;
                cursor: pointer;
                i{
                    font-size: 26px;
                }
            }
        }
        .TestColumn-dialog{
            min-width: 300px;
            position: absolute;
            right: 54px;
            top: 48px;
            border: 1px solid #409eff;
            text-align: left;
            color: #333;
            .TestColumn-dialog-top{
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #fff;
                padding: 0 10px;
                background: #409eff;
            }
            .TestColumn-dialog-body{
                background-color: #fff;
                .TestColumn-dialog-num{
                    font-size: 16px;
                    color: #333;
                    padding: 10px 10px;
                     em {
                        font-style: normal;
                        color: #409eff;
                    }
                }
                .TestColumn-dialog-type{
                    font-size: 12px;
                    padding: 8px 10px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    border-bottom: 1px dashed #ddd;
                    .TestColumn-dialog-del{
                        color: #409eff;
                        cursor: pointer;
                        border: none;
                        background: #fff;
                        &:hover .TestColumn-dialog-del{
                            color: #66b1ff;
                        }
                    }
                }
                .TestColumn-dialog-add {
                    display: -webkit-box;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    padding: 20px;
                    font-size: 14px;
                }
            }
            .TestColumn-dialog-foot{
                display: -webkit-box;
                display: flex;
                -webkit-box-pack: start;
                justify-content: start;
                -webkit-box-align: center;
                align-items: center;
                font-size: 14px;
                /*padding: 0 10px;*/
                height: 45px;
                background: #f5f9ff;
                .TestColumn-dialog-foot-left{
                    display: -webkit-box;
                    display: flex;
                    justify-content: flex-end;
                    -webkit-justify-content: flex-end;
                    -webkit-box-flex: 1;
                    flex: 1;
                    width: 1%;
                    span {
                        margin-right: 20px;
                        color: #409eff;
                        cursor: pointer;
                    }
                }
                .preview-btn{
                    padding: 6px 4px;
                }
            }

        }
    }
</style>