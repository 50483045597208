<template>
    <div class="Test-border">
        <div v-if="askAnswerData.askAnswer.length>0">
            <p class="Test-paper-title">{{getTranslateIndex}}、问答题</p>
            <draggable :list="askAnswerData.askAnswer" handle=".move-item">
                <div class="Test-paper-select Test-border Test-border-hover" v-for="(question,index) in askAnswerData.askAnswer" :key="index">
                    <div class="Test-border-border">
                        <div class="Test-paper-topic">
                            <span class="serial-number">{{index+1}}、</span>
                            <span v-html="question.topic.title" class="html-span"></span>
                        </div>
                        <div class="Test-Answer">
                            <ul class="Test-paper-test">
                                <li class="answer-group">
                                    <!--                                <div class="answer-title">答案：</div>-->
                                    <!--                                <span class="analyse-answer" v-html="question.topic.analyse"></span>-->
                                    <div class="analyse-answer-box">

                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="Test-paper-op">
                            <span class="anticon Anticonblue move-item">
                                <i class="el-icon-rank"></i>
                            </span>
                            <span class="anticon Anticondel" @click="deleteOne(question)">
                                 <i class="iconfont">&#xe879;</i>
                            </span>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
    import functions from "../../utils/common";
    import draggable from 'vuedraggable';
    export default {
        name: "PreviewQuestionsAnswersModule",
        data(){
            return{
            }
        },
        computed: {
            getTranslateIndex() {
                return functions.translateNumberByObject(this.askAnswerData, 'askAnswer');
            }
        },
        components: { draggable },
        props: ['askAnswerData'],
        methods: {
            translate(index) {
                return functions.translateNumber(index);
            },
            deleteOne(item) {
                this.$emit('deleteItem', item);
            }
        }
    }
</script>

<style scoped lang="scss">
    .Test-border{
        display: -webkit-box;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: left;
        padding: 0 30px 0 6px;
        border: 1px solid transparent;
        flex: 1;
        position: relative;
        .serial-number{
            float: left;
        }
        .Test-paper-title{
            padding: 15px 4px;
            font-weight: 700;
            margin: 0;
        }
        .Test-paper-op{
            position: absolute;
            right:-30px;
            top:10px;
            display: none;
            cursor: pointer;
            .anticon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background: #ddd;
                color: #fff;
                border-radius: 2px;
            }
            .Anticonblue{
                background: #409eff;
                color: #fff;
                &.move-item {
                    margin-bottom: 5px;
                }
            }
            .Anticondel{
                background: #f56c6c;
                color: #fff;
            }
        }
        .Test-paper-test{
            color: #333;
            padding-left: 10px;
            li{
                margin-bottom: 10px;
                list-style: none;
                .analyse-answer {
                    display: block;
                    padding: 8px 10px;
                    background-color: #E9E9EB;
                    color: #909399;
                }
                .analyse-answer-box {
                    height: 200px;
                    width: calc(100% - 8px);
                    border: 1px solid #EEE;
                }
            }
        }
        .Test-border-border{
            position: relative;
            border: 1px solid transparent;
        }
        .Test-border-hover:hover .Test-paper-op {
            display: block;
        }
        .Test-border-hover:hover .Test-border-border {
            border: 1px solid #409eff;
        }
        .Test-paper-topic {
            font-weight: 700;
            margin: 0;
            line-height: 2;
        }
    }
</style>